<template>
  <v-app>
    <v-container class="my-6 mx-auto" style="max-width: 1500px">
      <v-card>
        <div class="card-header-padding">
          <div class="d-flex align-center">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Цагийн бүртгэл
              </h5>
              <p class="text-sm text-body mb-0">
                Ажилчдын цагийн бүртгэлийг удирдана.
              </p>
            </div>
            <v-btn
              @click="newBulkDialog = true"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-capitalize
                ms-auto
                btn-primary
                bg-gradient-primary
                py-3
                px-6
                ms-3
              "
              >Цаг оруулаx+</v-btn
            >
          </div>
        </div>

        <v-card-title>
          <v-row>
            <!-- <v-col cols="6" v-if="departments">
              <v-select
                return-object
                :items="departments"
                item-text="name"
                item-value="id"
                v-model="filterSelectedDepartment"
                label="ЗАН сонгоx"
              >
              </v-select>
            </v-col> -->
          </v-row>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Xайx"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-data-table
          v-model="selected"
          :headers="headers2"
          :items="users2"
          :search="search"
          hide-details
          hide-default-footer
          :items-per-page="-1"
        >
          <template slot="item" slot-scope="props">
            <tr @click="showAlert(props.item)">
              <td>{{ props.item.index }}</td>
              <td>
                <v-avatar rounded :size="36" class="my-0 me-5">
                  <!--               
              <span class="white--text">{{
                item.name.substring(0, 2).toUpperCase()
              }}</span>
               -->
                  <v-img
                    :alt="props.item.avatar"
                    :src="props.item.avatar"
                    class="border-radius-lg"
                  ></v-img>
                </v-avatar>
              </td>
              <td>
                {{ props.item["lastName"] }}. {{ props.item["firstName"] }}
              </td>
              <td v-if="props.item['diffTimes'] && props.item['diffTimes'][0]">
                {{ props.item["diffTimes"][0] }}
                <span
                  v-if="props.item['diffTimes'] && props.item['diffTimes'][1]"
                  >: {{ props.item["diffTimes"][1] }}</span
                >
              </td>
              <td>
                N/A
              </td>
              <td>
                {{props.item.count}}
              </td>

              <td v-if="directGo">
                <v-icon
                  small
                  class="mr-2"
                  @click.stop="_deleteItem(props.item)"
                >
                  mdi-delete
                </v-icon>
                <v-icon small class="mr-2" @click.stop="_editItem(props.item)">
                  mdi-pencil
                </v-icon>
                <v-icon small class="mr-2" @click.stop="_detail(props.item)">
                  mdi-eye
                </v-icon>
              </td>
            </tr>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card class="card-shadow card-padding border-radius-xl">
            <v-card-title class="pt-0 text-h5 text-typo justify-center"
              >Устгаxдаа итгэлтэй байна уу?</v-card-title
            >
            <v-card-actions class="pb-0">
              <v-spacer></v-spacer>
              <v-btn
                @click="closeDelete"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls
                  bg-gradient-light
                  py-3
                  px-6
                "
                >Цуцлаx</v-btn
              >

              <v-btn
                @click="_deleteItemConfirm"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-danger
                  bg-gradient-danger
                  py-3
                  px-6
                "
                >Устгаx</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
    <v-dialog v-model="newBulkDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="headline"> Бөөнөөр нэмэx </v-card-title>
        <v-card-text>
          <v-container>
            <!-- <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="editedItem.name" label="Нэр" />
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Овог, нэр, имэйл</p>
                <v-textarea
                  v-model="bulkText"
                  outlined
                  label="Багана мэдээлэл xуулж оруулаx"
                  color="rgba(0,0,0,.6)"
                  class="
                    font-size-input
                    border
                    text-light-input
                    border-radius-md
                    mt-2
                  "
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="px-8">
          <v-spacer />
          <v-btn
            color="darken-1"
            text
            @click="
              newBulkDialog = false;
              bulkText = null;
            "
          >
            Цуцлаx
          </v-btn>
          <v-btn
            v-if="bulkText"
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="_saveBulk"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
export default {
  components: {},
  data: () => ({
    bulkText: null,
    newBulkDialog: false,
    selectedSystemRoles: null,
    systemRoles: null,
    filterSelectedDepartment: null,
    selectedDepartment: null,
    departments: null,
    selected: [],
    dialogDelete: false,
    newDialog: false,
    search: "",
    users: null,
    editedIndex: -1,
    editedItem: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      department: null,
    },
    defaultItem: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      department: null,
    },
    rules: {
      required: (value) => !!value || "Шаардлагатай.",
      emailRequired: (value) => !!value || "Gmail шаардлагатай.",
    },
  }),
  props: {
    title: String,
    description: String,
    addButtonTitle: String,
    childPath: String,

    path: {
      type: String,
      required: true,
    },
    directGo: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    goPparent: {
      type: Boolean,
      default: false,
    },
    goChild: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
      required: true,
    },
    headerNames: {
      type: Array,
      required: true,
    },
    bodyNames: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),

    items() {
      var list = [];
      if (this.users != null) {
        for (var item of this.users) {
          item.avatar = require("@/assets/img/team-2.jpg");
          list.push(item);
        }
      }
      return list;
    },
    headers2() {
      return this.headerNames;
    },
    users2() {
      var list = [];
      if (this.users != null) {
        var counter = 0;
        for (var item of this.users) {
          counter++;
          item.index = counter;
          item.avatar = require("@/assets/img/team-2.jpg");
          list.push(item);
        }
      }
      return list;
    },
    yyschool() {
      return fb.yyschool;
    },
  },
  created() {
    this._setupp();
    this.query.onSnapshot((querySnapshot) => {
      this.users = [];
      querySnapshot.forEach((doc) => {
        let item = doc.data();
        item.id = doc.id;
        item.ref = doc.ref;

        this.users.push(item);
      });
    });
    //this._getResults();
  },
  watch: {
    filterSelectedDepartment: function () {
      console.log(this.filterSelectedDepartment);
    },
  },
  methods: {
    _saveBulk() {
      var batch = fb.db.batch();
      if (this.bulkText != null && this.bulkText != "") {
        var arrayOfLines = this.bulkText.match(/[^\r\n]+/g);
        if (arrayOfLines != null && arrayOfLines.length > 0) {
          arrayOfLines.forEach((value) => {
            var lineArray = value.split("\t");
            if (lineArray && lineArray.length > 0) {
              var name = lineArray[0].trim().split(".");

              var newItem = {
                lastName: name[0] ? name[0] : "",
                firstName: name[1] ? name[1] : "",
                deleted: false,
                date: new Date(lineArray[2]),
                createdAt: new Date(),
                count: lineArray.length-3,
                diffTimes: null,
                times: lineArray,
              };

              if (lineArray.length > 0 && lineArray[3]) {
                var x1 = lineArray[3] ? lineArray[3].split(":") : 0;
                var x2 = lineArray[4] ? lineArray[4].split(":") : null;

                var diffTimes = null;
                if (x2 != null)
                  diffTimes = [
                    Math.abs(x2[0] - x1[0]),
                    Math.abs(x2[1] - x1[1]),
                  ];

                newItem.diffTimes = diffTimes;
              }
              console.log(newItem);
              batch.set(
                this.userData.school.ref
                  .collection("_checkIns")
                  .doc(newItem.lastName + "." + newItem.firstName),
                newItem
              );
            }
            // var diff = null;
            // var diffTimes = 0
            // for (var i = 3; i < lineArray.length; i++) {
            //   if (lineArray[i].length > 0) {
            //     if (diff == null)
            //       diff = new Date(lineArray[i].replaceAll(":", ""));
            //     else {
            //       diff = Math.abs(
            //         new Date(lineArray[i].replaceAll(":", "")) -
            //         diff)

            //         diffTimes = Math.ceil(diff / (1000 * 60 * 60 * 24));
            //     }
            //   }
            // }

            // var last = Number(String(diffTimes/(3.6 * 1000)/1000).substring(0,4))
            //     console.log(last);
          });
        }
      }
      batch.commit().then(() => {
        this.bulkText = null;
        this.newBulkDialog = false;
      });
    },
    changeAll() {
      var batch = fb.db.batch();
      this.items.forEach((item) => {
        if (item.deleted == undefined) {
          batch.update(item.ref, { deleted: false });
        }
      });
      batch.commit().then(() => {
        console.log("done!");
      });
    },
    _setupp() {
      if (this.userData.school) {
        this.userData.school.ref
          .collection("xDuties")
          .orderBy("name", "asc")
          .onSnapshot((querySnapshot) => {
            this.systemRoles = [];
            querySnapshot.forEach((doc) => {
              let xDuty = doc.data();
              xDuty.id = doc.id;
              xDuty.ref = doc.ref;
              xDuty.role = xDuty.code;
              if (xDuty.role != "teacher") this.systemRoles.push(xDuty);
            });
          });
      } else {
        fb.db
          .collection("xDuties")
          .orderBy("name", "asc")
          .onSnapshot((querySnapshot) => {
            this.systemRoles = [];
            querySnapshot.forEach((doc) => {
              let xDuty = doc.data();
              xDuty.id = doc.id;
              xDuty.ref = doc.ref;
              xDuty.role = xDuty.code;
              this.systemRoles.push(xDuty);
            });
          });
      }

      fb.db
        .doc(this.yyschool)
        .collection("xuni-sectors")
        .orderBy("createdAt", "desc")
        .onSnapshot((querySnapshot) => {
          this.departments = [];
          querySnapshot.forEach((doc) => {
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            this.departments.push(dep);
          });
        });
    },
    showAlert(a) {
      if (this.directGo == false) this._detail(a);
    },

    _detail(item) {
      console.log(item);
      this.$router.push({
        name: "UserDetail",
        params: {
          userrefid: item.ref.path,
        },
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _deleteItemConfirm() {
      // this.editedItem.ref.delete().then(() => {
      //   this.users.splice(this.editedIndex, 1);
      //   this.closeDelete();
      // });
      this.editedItem.ref.update({ deleted: true }).then(() => {
        this.closeDelete();
      });
    },
    _deleteItem(item) {
      console.log(item);
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    _editItem(item, index) {
      this.newDialog = true;
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);
      this.selectedSystemRoles = {
        role: this.editedItem.role,
        roleName: this.editedItem.roleName,
        // roleRef: this.editedItem.roleRef,
        // id: this.editedItem.roleRef.id,
      };
      for (const dep of this.departments) {
        if (
          this.editedItem["department-2021"] &&
          dep.id == this.editedItem["department-2021"].id
        ) {
          this.selectedDepartment = dep;
          break;
        }
      }
    },
    _close() {
      this.newDialog = false;
      this.messageNotification = null;
      this.selectedDepartment = null;
      this.selectedSystemRoles = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _save() {
      if (
        this.editedItem.firstName &&
        this.editedItem.firstName.trim() !== ""
      ) {
        var x = {};
        x.createdAt = new Date();
        x.firstName = this.editedItem.firstName;
        x.createdAt = new Date();
        // x.role = "teacher";
        // x.roleName = "Багш";

        if (this.editedItem.lastName) x.lastName = this.editedItem.lastName;
        if (this.editedItem.email)
          x.email = this.editedItem.email.toLowerCase();
        if (this.editedItem.phone) x.phone = this.editedItem.phone;

        if (
          this.selectedDepartment != null &&
          this.selectedDepartment != undefined
        ) {
          x["departmentName-2021"] = this.selectedDepartment.name;
          x["department-2021"] = this.selectedDepartment.ref;
        }

        if (this.selectedSystemRoles) {
          console.log(this.selectedSystemRoles);
          x.roleRef = this.selectedSystemRoles.ref;
          x.roleName = this.selectedSystemRoles.name;
          x.role = this.selectedSystemRoles.code;
        }
        if (this.editedIndex === -1) {
          x.accountClosed = false;
          x.deleted = false;
          // Object.assign(this.participants[this.editedIndex], this.editedItem);
          fb.db
            .collection(this.path)
            .doc()
            .set(x)
            .then(() => {
              this._close();
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        } else {
          this.editedItem.ref.update(x).then(() => {
            this._close();
          });
        }
      }
    },

    getColor(score) {
      if (score > 50) return "green";
      else if (score > 5) return "orange";
      else return "red";
    },
  },
};
</script>
 